"use client";
import { Next13ProgressBar } from "next13-progressbar";
import { Suspense } from "react";

const ProgressBar = () => {
  return (
    <Suspense>
      <Next13ProgressBar
        height="4px"
        color="rgba(0,0,0,0.2)"
        options={{ showSpinner: false }}
      />
    </Suspense>
  );
};

export default ProgressBar;
