"use client";

import React, { useEffect, useRef, useState } from "react";
import { urlForImageFrontEnd } from "@/sanity/client"; // Adjust the path as needed
import { AssetRefT } from "@/types/dataTypes";
import Image from "next/image";
import joinClassNames from "@/utils/classNames";

type VideoProps = {
  video: string;
  posterImage?: AssetRefT;
  width?: number;
  height?: number;
  controls?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  className?: string;
  alt?: string;
  playsInline?: boolean;
};

const Video: React.FC<VideoProps> = ({
  video,
  posterImage,
  width = 1280,
  height = 720,
  controls = false,
  autoPlay = false,
  muted = false,
  loop = false,
  className,
  alt,
  playsInline = true,
}) => {
  const posterUrl = posterImage
    ? urlForImageFrontEnd({ image: posterImage, width, height })
    : undefined;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isSuspended, setIsSuspended] = useState(false);

  useEffect(() => {
    const videoEl = videoRef?.current;
    videoEl?.addEventListener("suspend", () => setIsSuspended(true));
    videoEl?.addEventListener("play", () => setIsSuspended(false));

    return () => {
      videoEl?.removeEventListener("suspend", () => setIsSuspended(true));
      videoEl?.removeEventListener("play", () => setIsSuspended(false));
    };
  }, [videoRef]);

  return (
    <div className="relative">
      <video
        ref={videoRef}
        className={className}
        width={width}
        height={height}
        controls={controls}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        poster={posterUrl}
        playsInline={playsInline}
      >
        <track kind="captions" />
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {isSuspended && posterUrl && (
        <Image
          className={joinClassNames(className, "absolute inset-0 ")}
          src={posterUrl}
          alt={String(alt)}
          width={width}
          height={height}
          loading="lazy"
          unoptimized
        />
      )}
    </div>
  );
};

export default Video;
