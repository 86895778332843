"use client";

import Container from "@/components/furniture/Container";
import Section from "@/components/furniture/Section";
import HeroModule from "@/components/modules/HeroModule";

export default function Error({ error }: { error: Error }) {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <Section>
      <Container>
        <HeroModule
          title="Error"
          text={
            isProduction && error.message
              ? "Sorry, there was a problem rendering this page, please try again later."
              : error.message
          }
          size="small"
        />
      </Container>
    </Section>
  );
}
