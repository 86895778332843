"use client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import type { MenuItemsT } from "@/types/dataTypes";
import NavMenu from "@/components/furniture/navigation/NavMenu";
import joinClassNames from "@/utils/classNames";
import usePreventBodyScroll from "@/hooks/usePreventBodyScroll";
import { useTheme } from "@/hooks/useTheme";

type NavigationProps = {
  menuItems: MenuItemsT;
  className?: string;
};

const Navigation = ({ menuItems, className }: NavigationProps) => {
  const path = usePathname();
  const theme = useTheme();
  const segment = path.split("/")[1];

  usePreventBodyScroll(!!theme?.showMobileNavigation);

  useEffect(() => {
    theme?.handleHideMobileNavigation();
    // eslint-disable-next-line
  }, [path]);

  return (
    <nav className={className}>
      <div className="relative w-full flex items-center justify-end">
        <button
          className="md:hidden w-6 flex justify-end items-center mobile-tap-area"
          onClick={theme?.handleShowMobileNavigation}
        >
          <span className="absolute -inset-0.5" />
          <span className="sr-only">Open main menu</span>
          <Bars3Icon
            aria-hidden="true"
            className="block h-6 w-6 text-theme-tertiary"
          />
        </button>

        <div className="hidden md:block">
          <NavMenu menuItems={menuItems} path={segment} />
        </div>
      </div>
      <div
        className={joinClassNames(
          "fixed inset-0 z-50 bg-cream transition-show-hide md:hidden",
          !!theme?.showMobileNavigation && "is-visible",
        )}
      >
        <div className="w-auto absolute top-11 right-5">
          <button
            className="flex justify-end items-center  mobile-tap-area"
            onClick={theme?.handleHideMobileNavigation}
          >
            <span className="absolute -inset-0.5" />
            <span className="sr-only">Close main menu</span>
            <XMarkIcon
              aria-hidden="true"
              className="absolute block h-6 w-6 text-black"
            />
          </button>
        </div>
        <NavMenu menuItems={menuItems} display="vertical" path={segment} />
      </div>
    </nav>
  );
};

export default Navigation;
