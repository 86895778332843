import { useEffect } from "react";

export default function usePreventBodyScroll(locked = false) {
  useEffect(() => {
    if (locked) {
      document.querySelector("html")?.classList.add("overflow-clip");
    }

    return () => {
      document.querySelector("html")?.classList.remove("overflow-clip");
    };
  }, [locked]);
}
