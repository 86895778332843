"use client";

import { Link } from "next-view-transitions";
import dynamic from "next/dynamic";
import type { MenuItemsT } from "@/types/dataTypes";

import joinClassNames from "@/utils/classNames";
import Routes from "@/router/Routes";

const Scribble = dynamic(() => import("@/components/icons/markers/Scribble"));

type MainMenuProps = {
  menuItems: MenuItemsT;
  display?: "horizontal" | "vertical";
  className?: string;
  path: string;
};

const isCurrent = (slug: string, path: string) => path.includes(slug);

const WRAPPER_BASE_CLASSES = "w-full flex items-center";

const WRAPPER_DISPLAY_CLASSES = {
  horizontal: "justify-start ",
  vertical: "h-full flex-col justify-center",
};

const ITEM_BASE_CLASSES = "whitespace-nowrap";

const ITEM_DISPLAY_CLASSES = {
  horizontal: "p-5 text-theme-tertiary",
  vertical: "text-black font-serif text-3xl",
};

export default function NavMenu({
  menuItems,
  display = "horizontal",
  className,
  path,
}: MainMenuProps) {
  if (!menuItems || menuItems.length === 0) return <></>;

  return (
    <ul
      className={joinClassNames(
        WRAPPER_BASE_CLASSES,
        WRAPPER_DISPLAY_CLASSES[display],
        className,
      )}
    >
      {menuItems.map(item => {
        const current = isCurrent(item.slug, path);
        return (
          <li
            key={item.name}
            className={joinClassNames(
              ITEM_BASE_CLASSES,
              ITEM_DISPLAY_CLASSES[display],
            )}
          >
            <div className="flex flex-col justify-start items-center leading-0">
              <Link
                href={Routes[item._type as keyof typeof Routes](item.slug)}
                key={item.name}
                aria-current={current ? "page" : undefined}
                className="opacity-hover"
              >
                {item.name}
              </Link>
              <div className="h-2 relative">
                {current && (
                  <Scribble
                    width={40}
                    className="absolute-center fill-theme-tertiary"
                  />
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
