"use client";

import dynamic from "next/dynamic";
import { Suspense } from "react";

const CookieToggleButton = dynamic(
  () => import("@/components/cookie-consent/CookieToggleButton"),
  { ssr: false },
);

const FooterCookieSettings = () => {
  return (
    <Suspense>
      <CookieToggleButton />
    </Suspense>
  );
};

export default FooterCookieSettings;
