"use client";
import Vimeo from "@u-wave/react-vimeo";

import type { ModulesProps } from "@/types/dataTypes";
import Container from "@/components/furniture/Container";
import Section from "@/components/furniture/Section";
import Eyebrow from "@/components/typography/Eyebrow";
import joinClassNames from "@/utils/classNames";

const VideoModule = ({
  vimeoId,
  moduleWidth,
  caption,
}: ModulesProps["videoModule"]) => {
  if (!vimeoId) return <></>;

  return (
    <Section>
      <Container width={moduleWidth}>
        <figure>
          <Vimeo
            video={vimeoId}
            autoplay
            muted
            responsive
            width="100%"
            className="w-full"
            controls={false}
          />
          {!!caption && (
            <Eyebrow
              className={joinClassNames(
                "py-4",
                moduleWidth === "full" && "px-4"
              )}
              as="figcaption"
            >
              {caption}
            </Eyebrow>
          )}
        </figure>
      </Container>
    </Section>
  );
};
export default VideoModule;
